import { render } from "./MeasurementDataItem.vue?vue&type=template&id=1d238f39"
import script from "./MeasurementDataItem.vue?vue&type=script&setup=true&lang=js"
export * from "./MeasurementDataItem.vue?vue&type=script&setup=true&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1d238f39"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1d238f39', script)) {
    api.reload('1d238f39', script)
  }
  
  module.hot.accept("./MeasurementDataItem.vue?vue&type=template&id=1d238f39", () => {
    api.rerender('1d238f39', render)
  })

}

script.__file = "src/components/MeasurementDataItem.vue"

export default script