<template>
  <a-row type="flex" :gutter="[8, 64]">
    <a-col flex="100px">
      <strong>{{ props.field }}:</strong>
    </a-col>
    <a-col flex="120px" align="right">
      {{ props.value }}
      <slot></slot>
    </a-col>
    <a-col flex="60px" align="left">{{ props.unit }}</a-col>
  </a-row>
</template>

<script setup>
  import { defineProps } from 'vue'
  const props = defineProps({
    field: String,
    value: Number,
    unit: String,
  })
</script>
