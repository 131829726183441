<template>
  <div class="recordDetail-container">
    <a-page-header title="健康紀錄詳細資料">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <a-spin :spinning="isLoading" tip="Loading...">
      <div v-if="recordData.user">
        <div class="record-basci">
          <a-form-item label="紀錄編號">
            <span>{{ recordData.id }}</span>
          </a-form-item>
          <a-form-item label="量測日期">
            <span>{{ parseTime(recordData.created) }}</span>
          </a-form-item>
          <a-form-item label="上傳日期">
            <span>
              {{ parseTime(recordData.updated) }} {{ recordData.updated }}
            </span>
          </a-form-item>
          <a-form-item label="上傳來源">
            <span>{{ recordData.hubCode }}</span>
          </a-form-item>
        </div>
        <a-tabs type="card" @change="callback">
          <a-tab-pane key="1" :disabled="!recordData.user">
            <template #tab>
              <span>會員資料</span>
            </template>
            <MeasurementDataItem field="姓名" :value="recordData.user.name" />
            <MeasurementDataItem
              field="會員卡號"
              :value="cardNoHidden(recordData.user.cardId)"
            />
            <MeasurementDataItem
              field="會員等級"
              :value="recordData.user.levelName"
            />
            <MeasurementDataItem
              field="聯絡電話"
              :value="recordData.user.phoneNumber"
            />
            <!-- <a-form-item label="個案編號">
              <span>{{ recordData.user.caseNo }}</span>
            </a-form-item>
            <a-form-item label="門市">
              <span>{{ recordData.user.store }}</span>
            </a-form-item> -->
          </a-tab-pane>
          <a-tab-pane key="2" :disabled="recordData.bpMeasurements.length == 0">
            <template #tab>
              <span>血壓</span>
            </template>
            <MeasurementDataItem
              field="收縮壓"
              :value="recordData.bpMeasurements[0]?.systolic"
              unit="mmHg"
            />
            <MeasurementDataItem
              field="舒張壓"
              :value="recordData.bpMeasurements[0]?.diastolic"
              unit="mmHg"
            />
            <MeasurementDataItem
              field="脈壓差"
              :value="recordData.bpMeasurements[0]?.pulsePressureDifference"
              unit="mmHg"
            />
            <MeasurementDataItem field="心律不整">
              <span
                v-if="recordData.bpMeasurements[0]?.arr"
                style="color: #8b2331"
              >
                YES
              </span>
              <span v-else>NO</span>
            </MeasurementDataItem>
            <MeasurementDataItem field="心房顫動">
              <span
                v-if="recordData.bpMeasurements[0]?.pc"
                style="color: #8b2331"
              >
                YES
              </span>
              <span v-else>NO</span>
            </MeasurementDataItem>
            <MeasurementDataItem field="早期收縮">
              <span
                v-if="recordData.bpMeasurements[0]?.afib"
                style="color: #8b2331"
              >
                YES
              </span>
              <span v-else>NO</span>
            </MeasurementDataItem>
            <MeasurementDataItem
              field="脈搏"
              :value="recordData.bpMeasurements[0]?.pulse"
              unit="次/分鐘"
            />
            <MeasurementDataItem
              field="量測設備"
              :value="recordData.bpMeasurements[0]?.deviceID"
            />
          </a-tab-pane>
          <a-tab-pane
            key="3"
            :disabled="recordData.glucoseMeasurements.length == 0"
          >
            <template #tab>
              <span>血糖</span>
            </template>
            <MeasurementDataItem
              field="用餐狀態"
              :value="mealString(recordData.glucoseMeasurements[0]?.meal)"
            />
            <MeasurementDataItem
              field="血糖"
              :value="recordData.glucoseMeasurements[0]?.bloodGlucose"
              unit="mg/dL"
            />
            <MeasurementDataItem
              field="量測設備"
              :value="recordData.glucoseMeasurements[0]?.deviceID"
            />
          </a-tab-pane>
          <a-tab-pane
            key="4"
            :disabled="recordData.oxygenSatMeasurements.length == 0"
          >
            <template #tab>
              <span>血氧</span>
            </template>
            <MeasurementDataItem
              field="SpO2"
              :value="
                recordData.oxygenSatMeasurements[0]?.spo2Lowest +
                '~' +
                recordData.oxygenSatMeasurements[0]?.spo2Highest
              "
              unit="%"
            />
            <!-- <a-form-item label="ACT">
              <span>
                {{ recordData.oxygenSatMeasurements[0]?.ACTHighest }}
              </span>
              <span>等級</span>
            </a-form-item> -->
            <MeasurementDataItem
              field="量測設備"
              :value="recordData.oxygenSatMeasurements[0]?.deviceID"
            />
          </a-tab-pane>
          <a-tab-pane
            key="5"
            :disabled="recordData.bodyWeightFatMeasurements.length == 0"
          >
            <template #tab>
              <span>身體組成</span>
            </template>
            <MeasurementDataItem
              v-for="item in bodyWeightFatFields"
              :key="item.field"
              :field="item.displayName"
              :value="preproccessBodyWeightFatData[item.field]"
              :unit="item.unit"
            />
            <MeasurementDataItem
              field="量測設備"
              :value="recordData.bodyWeightFatMeasurements[0]?.deviceID"
            />
          </a-tab-pane>
          <a-tab-pane
            key="6"
            :disabled="recordData.bodyTempMeasurements.length == 0"
          >
            <template #tab>
              <span>體溫</span>
            </template>
            <MeasurementDataItem
              field="預設額溫"
              :value="
                round(recordData.bodyTempMeasurements[0]?.bodyTemperature, 1)
              "
              unit="度"
            />
            <MeasurementDataItem
              field="量測設備"
              :value="recordData.bodyTempMeasurements[0]?.deviceID"
            />
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'
  import MeasurementDataItem from '@/components/MeasurementDataItem.vue'
  import { onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { getRecordDetail } from '@/api/healthy'
  import { round, mealString, parseTime, cardNoHidden } from '@/utils/index'
  import {
    getMeasurementDisplayFields,
    preprocessBodyWeightFat,
  } from '@/utils/measurement'
  export default {
    name: 'recordDetail',
    components: {
      QuestionCircleOutlined,
      MeasurementDataItem,
    },
    setup() {
      const route = useRoute()
      const store = useStore()
      // const router = useRouter()
      const recordData = ref({})
      const preproccessBodyWeightFatData = ref({})
      const bodyWeightFatFields = ref([])
      const isLoading = ref(false)
      const callback = (key) => {
        console.log(key)
      }
      onMounted(async () => {
        isLoading.value = true
        const { data } = await getRecordDetail({ id: route.params.recordNo })
        isLoading.value = false
        recordData.value = data.data

        bodyWeightFatFields.value = getMeasurementDisplayFields(
          'bodyWeightFat',
          recordData.value.bodyWeightFatMeasurements[0]?.deviceID
        )
        if (recordData.value.bodyWeightFatMeasurements[0]) {
          preproccessBodyWeightFatData.value = preprocessBodyWeightFat(
            recordData.value.bodyWeightFatMeasurements[0]
          )
        }

        if (recordData.value.subject == null) {
          recordData.value.user = {
            name: '訪客',
            cardId: '',
            levelName: '流水客',
            phoneNumber: '',
          }
        } else {
          const custInfo = await store.dispatch('cust/getCustInfo', {
            memberID: recordData.value.subject.memberID,
          })
          recordData.value.user = custInfo
        }
      })
      return {
        isLoading,
        callback,
        recordData,
        bodyWeightFatFields,
        preproccessBodyWeightFatData,
        round,
        getMeasurementDisplayFields,
        mealString,
        parseTime,
        cardNoHidden,
      }
    },
  }
</script>

<style lang="less">
  .spin-content {
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    padding: 30px;
  }
  .recordDetail-container {
    .record-basci {
      width: 100%;
      margin: 10px 0px;
      background: #fff;
      padding: 16px;
    }
    .ant-tabs-card {
      .ant-tabs-tab {
        position: relative;
        div.circle {
          position: absolute;
          right: 3px;
          top: 3px;
          width: 6px;
          height: 6px; //正圓形，所以寬與高都設一樣
          border-radius: 999em;
          background-color: #de1306;
        }
      }
      .ant-tabs-content {
        // height: 120px;
        margin-top: -16px;
        .ant-tabs-tabpane {
          background: #fff;
          padding: 16px;
        }
      }
      .ant-tabs-bar {
        .ant-tabs-tab {
          border: 1px solid #6e6e6e;
          background: transparent;
        }
        .ant-tabs-tab-active {
          border-top: 3px solid #000000;
          box-sizing: border-box;
          border-bottom: #fff;
          background: #fff;
        }
      }
    }

    .ant-form {
      padding: 15px 0px;
      background-color: #fff;
      .ant-row {
        font-size: 20px;
      }
      label {
        font-size: 20px;
      }
    }
    .ant-form-item-children {
      span:first-child {
        min-width: 100px;
        display: inline-block;
      }
    }
  }
</style>
