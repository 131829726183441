import { render } from "./_recordNo.vue?vue&type=template&id=41e7c596"
import script from "./_recordNo.vue?vue&type=script&lang=js"
export * from "./_recordNo.vue?vue&type=script&lang=js"

import "./_recordNo.vue?vue&type=style&index=0&id=41e7c596&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "41e7c596"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('41e7c596', script)) {
    api.reload('41e7c596', script)
  }
  
  module.hot.accept("./_recordNo.vue?vue&type=template&id=41e7c596", () => {
    api.rerender('41e7c596', render)
  })

}

script.__file = "src/views/healthManagement/healthRecord/_recordNo.vue"

export default script